<template>
    <div class="bannerControl">
        <div class="bot">
            <p class="tips">
                * 建议尺寸750px*300px，上传图片不超过1M，支持png、bmg、jpeg、jpg、gif
            </p>
            <vuedraggable v-model="data" v-bind="{
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
                scroll: true
            }" @change="changeSort">
                <transition-group>
                    <div class="itemBox" v-for="(item, index) in data" :key="index">
                        <div class="left">
                            <div class="leftL">
                                <span @click="moveClick(0, index, item)" :class="index == 0 ? 'fcccc' : ''">&lt;</span>
                                <span @click="moveClick(1, index, item)"
                                    :class="index == data.length - 1 ? 'fcccc' : ''">></span>
                            </div>
                            <div class="leftR">
                                <img :src="item.imgURL" alt="" />
                            </div>
                        </div>
                        <div class="right">
                            <div>
                                <div class="inputBox">
                                    <el-input v-model="data[index].imgURL" disabled placeholder="选择图片"></el-input>
                                    <el-button type="primary" @click="chooseImg(index)">选择</el-button>
                                </div>
                                <div class="inputBox">
                                    <el-input v-model="item.toPage" disabled></el-input>
                                    <el-button type="primary" @click="toPageShow(index)">选择</el-button>
                                </div>
                            </div>
                            <div @click="deleteItem(index)" style="justify-content: center;">
                                <i :class="['iconfont', index != 0 ? 'icon-guanbi' : '']"></i>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </vuedraggable>
            <div @click="addBanner" class="addOne">新增一个</div>
        </div>
        <!-- 选择跳转页面弹框 -->
        <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
            <div>
                <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
            </div>
        </el-dialog>
        <!-- 选择图片弹框 -->
        <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag"
            @getSource="getImgRes" :maxSize="1024 * 1024"></UploadSource>
    </div>
</template>
  
<script>
import vuedraggable from 'vuedraggable'
import ToPage from '@/repair/components/toPage'
import UploadSource from '@/components/uploadSource'
export default {
    props: ['data'],
    components: {
        vuedraggable,
        UploadSource,
        ToPage
    },
    data () {
        return {
            index: -1,
            chooseImgFlag: false,
            toPageFlag: false,
        }
    },
    watch: {
        data () {
            this.updateData()
        }
    },
    methods: {
        updateData () {
            this.$emit('update-data', this.data)
        },
        changeStatus (val) {
            this.chooseImgFlag = val
        },
        addBanner () {
            if (this.data.length == 5) {
                this.$message({
                    message: '最多只可以添加五张banner图哦~',
                    type: 'error'
                })
                return
            }
            this.data.push({
                imgURL: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png',
                toPage: '跳转页面'
            })
        },
        // 点击移动banner图位置
        moveClick (status, index, item) {
            if (status == 0) {
                // 向上移
                if (index == 0) {
                    this.$message({
                        message: '已经在最前面了~',
                        type: 'error'
                    })
                    return
                }
                this.data.splice(index, 1)
                this.data.splice(index - 1, 0, item)
            } else {
                // 向下移
                if (index == this.data.length - 1) {
                    this.$message({
                        message: '已经在最后面了~',
                        type: 'error'
                    })
                    return
                }
                this.data.splice(index + 2, 0, item)
                this.data.splice(index, 1)
            }
        },
        // 删除对应banner图
        deleteItem (index) {
            this.data.splice(index, 1)
        },
        // 拿到图片
        getImgRes (imglist) {
            this.data[this.index].imgURL = imglist[0].path
        },
        // 选择图片
        chooseImg (index) {
            this.chooseImgFlag = true
            this.index = index
        },
        // 开启选择链接弹框
        toPageShow (index) {
            this.toPageFlag = true
            this.itemIndex = index
            this.$nextTick(() => {
                this.$refs.toPage.getPageArr()
            })
        },
        // 拿到跳转路径
        getPageRes (item) {
            this.data[this.itemIndex].toPage = item.wxroute
            this.toPageFlag = false
        },
    }
}
</script>
  
<style lang="less" scoped>
.fcccc {
    color: #ccc;
    border: 1px solid #ccc !important;
    cursor: no-drop !important;
}

.bannerControl {
    width: 100%;
    padding: 30px;

    .pageName {
        margin-bottom: 20px;
    }

    .bot {

        .bgColor {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            p {
                margin-right: 20px;
            }

            .el-color-picker/deep/.el-color-picker__trigger {
                width: 150px;
            }
        }

        .tips {
            font-size: 14px;
            color: #9a9a9a;
        }

        .itemBox {
            width: 100%;
            display: flex;
            height: 100px;
            margin-top: 20px;

            .left {
                display: flex;

                .leftL {
                    font-family: SimSun;
                    width: 30px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-right: 20px;

                    span {
                        width: 16px;
                        height: 16px;
                        line-height: 16px;
                        text-align: center;
                        border-radius: 5px;
                        display: block;
                        border: 1px solid #333;
                        font-weight: bold;
                        cursor: pointer;
                    }

                    span:first-child {
                        transform: rotate(90deg);
                    }

                    span:last-child {
                        transform: rotate(90deg);
                        margin-top: 10px;
                    }
                }

                .leftR {
                    height: 100%;
                    width: 220px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .right {
                flex: 1;
                display: flex;
                justify-content: space-around;
                align-items: center;

                &>div {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                .inputBox {
                    width: 220px;
                    height: 40px;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    position: relative;

                    p {
                        width: 150px;
                        margin-left: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #9e9e9e;
                    }
                }
            }
        }

        .addOne {
            width: 100%;
            height: 40px;
            text-align: center;
            line-height: 40px;
            color: #3c76ff;
            border: 1px solid #3c76ff;
            cursor: pointer;
            margin-top: 20px;
        }
    }
}</style>
  