<template>
    <div class="header">
        <div class="pageName">
            <p>数据标题</p>
            <el-input placeholder="请输入数据标题" v-model="data.title" maxlength="10" show-word-limit></el-input>
        </div>
        <div class="pageName">
            <p>专业入驻技工基数</p>
            <el-input placeholder="请输入专业入驻技工基数" v-model="data.artisan_num" type="number"></el-input>
        </div>
        <div class="pageName">
            <p>累计服务订单基数</p>
            <el-input placeholder="请输入累计服务订单基数" v-model="data.order_num" type="number"></el-input>
        </div>
        <div class="pageName">
            <p>服务好评率</p>
            <el-switch v-model="data.is_true" active-value="1" inactive-value="0"></el-switch>
            <div class="tips">默认为固定值，开启后为真实值</div>
        </div>
        <el-input placeholder="请输入服务好评率" v-model="data.rate" type="number" style="margin-left:160px;"
            v-if="data.is_true == '0'">
            <template slot="append">%</template>
        </el-input>
    </div>
</template>
  
<script>
export default {
    props: ['data'],
}
</script>
  
<style lang="less" scoped>
.header {
    padding: 30px;

    .pageName {
        display: flex;
        margin-bottom: 20px;

        p {
            width: 160px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            white-space: nowrap;
        }



        .el-radio-group {
            height: 40px;
            display: flex;
            align-items: center;
        }

        .el-checkbox-group {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .el-checkbox {
                margin: 0 10px 10px 0;
            }
        }

        .el-color-picker {
            width: 200px;

            & /deep/ .el-color-picker__trigger {
                width: 100%;
            }
        }
    }

    .el-input {
        max-width: 300px;
    }

    .tips {
        font-size: 14px;
        color: #9a9a9a;
        margin-left: 30px;
    }
}
</style>
  