<template>
    <el-main>
        <div class="box">
            <div style="height: 650px; position: relative; width: 350px; margin: 54px 0 0 15px">
                <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/multiversion_phoneheader2.png" alt=""
                    class="imagebg" />

                <div class="contain">
                    <!-- 定位 -->
                    <div class="location">
                        <div>深圳市</div>
                        <i class="el-icon-cart-right"></i>
                    </div>
                    <!-- 搜索 -->
                    <div class="search">
                        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/community/index/search.png" alt="" />
                        <div>请输入搜索内容</div>
                    </div>
                    <div class="part1">
                        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/community/index/slogan.png" alt=""
                            class="slogan" />
                        <!-- 图片橱窗 -->
                        <div :class="['images', chooseset == 'images' ? 'component_checked' : 'component_canset']"
                            @click="changeSet('images')">
                            <img :src="item.imgUrl" alt="" v-for="(item, index) in images" :key="index" />
                        </div>

                        <!-- 按钮组 -->
                        <div v-if="!isShow"
                            :class="['buttons', chooseset == 'button' ? 'component_checked' : 'component_canset']"
                            :style="{ 'background-color': buttons.bgColor }" @click="changeSet('button')">
                            <div class="button">
                                <div class="button_item"
                                    v-for="(item, index) in buttons.list.slice(0, buttons.num * 1 > 5 ? buttons.num / 2 : buttons.num)"
                                    :key="index">
                                    <img :src="item.img" />
                                    <span>{{ item.name }}</span>
                                </div>
                            </div>
                            <div class="button" v-if="buttons.num * 1 > 5" style="margin-top: 12px">
                                <div class="button_item"
                                    v-for="(item, index) in buttons.list.slice(buttons.num / 2, buttons.num)" :key="index">
                                    <img :src="item.img" />
                                    <span>{{ item.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 轮播图 -->
                    <div :class="['carousel', chooseset == 'banner' ? 'component_checked' : 'component_canset']"
                        @click="changeSet('banner')">
                        <el-carousel height="93px" class="carousel_box">
                            <el-carousel-item v-for="(item, index) in banner" :key="index">
                                <img :src="item.imgURL" alt="img_url" />
                            </el-carousel-item>
                        </el-carousel>
                    </div>

                    <div class="part2">
                        <div class="title">功能服务</div>
                        <!-- 图片橱窗 -->
                        <div :class="['images2', chooseset == 'images2' ? 'component_checked' : 'component_canset']"
                            @click="changeSet('images2')">
                            <img :src="images2[0].imgUrl" alt="" class="img1" />
                            <img :src="images2[1].imgUrl" alt="" class="img1" />
                        </div>
                    </div>

                    <div :class="['data', chooseset == 'data' ? 'component_checked' : 'component_canset']"
                        @click="changeSet('data')">
                        <div class="row1">
                            <div class="line1"></div>
                            <div class="row1_title">{{ data.title }}</div>
                            <div class="line2"></div>
                        </div>
                        <div class="row2">
                            <div class="row2_item">
                                <div class="row2_item_top">
                                    <div class="row2_item_num">{{ data.artisan_num / 10000 }}</div>
                                    <div class="row2_item_unit">万+</div>
                                </div>
                                <div class="row2_item_text">专业入驻师傅</div>
                            </div>
                            <div class="line3"></div>
                            <div class="row2_item">
                                <div class="row2_item_top">
                                    <div class="row2_item_num">{{ data.order_num / 10000 }}</div>
                                    <div class="row2_item_unit">万+</div>
                                </div>
                                <div class="row2_item_text">累计服务订单</div>
                            </div>
                            <div class="line3"></div>
                            <div class="row2_item">
                                <div class="row2_item_top">
                                    <div class="row2_item_num">{{ data.rate }}</div>
                                    <div class="row2_item_unit">%</div>
                                </div>
                                <div class="row2_item_text">服务好评率</div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/community/index/tabbar.png" alt="img"
                    class="imagetabbar" />
            </div>
        </div>
        <div class="flex1"></div>
        <!-- 设置 -->
        <div class="setting">
            <div class="setting_header">{{ componentArr.find(item => item.val == chooseset).label }}</div>
            <div class="setting_part">
                <ImagesControl v-if="chooseset == 'images'" :controlDefault="images"
                    @update-data="updateData($event, 'images')"></ImagesControl>
                <Button v-if="chooseset == 'button'" :buttons="buttons" :isShow="isShow"
                    @changeShow="changeisShow($event)"></Button>
                <banner v-if="chooseset == 'banner'" :data="banner" @update-data="updateData($event, 'banner')"></banner>
                <ImagesControl v-if="chooseset == 'images2'" :controlDefault="images2"
                    @update-data="updateData($event, 'images2')"></ImagesControl>
                <DataControl v-if="chooseset == 'data'" :data="data"></DataControl>
            </div>
        </div>
        <Preservation @preservation="submit"></Preservation>
    </el-main>
</template>
 
<script>
import { imgUrlHead } from '@/util/config.js';
import Preservation from '@/components/preservation';
import ImagesControl from './components/imagesControl';
import banner from './components/banner';
import Button from './components/button';
import DataControl from './components/dataControl';
export default {
    components: {
        Preservation,
        banner,
        Button,
        ImagesControl,
        DataControl,
    },
    data: function () {
        return {
            isShow: false,
            imgH: imgUrlHead,
            chooseset: 'images',
            componentArr: [
                {
                    label: '图片橱窗',
                    val: 'images',
                },
                {
                    label: '按钮组',
                    val: 'button',
                },
                {
                    label: '轮播图',
                    val: 'banner',
                },
                {
                    label: '图片橱窗',
                    val: 'images2',
                },
                {
                    label: '数据显示',
                    val: 'data',
                },
            ],
            images: [
                {
                    imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/community/index1%20(1).png',
                    toPage: '',
                    size: '206px*126px',
                },
                {
                    imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/community/index1%20(2).png',
                    toPage: '',
                    size: '206px*126px',
                },
                {
                    imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/community/index1%20(4).png',
                    toPage: '',
                    size: '206px*126px',
                },
                {
                    imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/community/index1%20(5).png',
                    toPage: '',
                    size: '206px*126px',
                },
            ],
            buttons: {
                num: '10',
                bgColor: '#ffffff',
                list: [
                    {
                        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                        name: '按钮文字',
                        page: '跳转页面',
                    },
                    {
                        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                        name: '按钮文字',
                        page: '跳转页面',
                    },
                    {
                        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                        name: '按钮文字',
                        page: '跳转页面',
                    },
                    {
                        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                        name: '按钮文字',
                        page: '跳转页面',
                    },
                    {
                        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                        name: '按钮文字',
                        page: '跳转页面',
                    },
                    {
                        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                        name: '按钮文字',
                        page: '跳转页面',
                    },
                    {
                        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                        name: '按钮文字',
                        page: '跳转页面',
                    },
                    {
                        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                        name: '按钮文字',
                        page: '跳转页面',
                    },
                    {
                        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                        name: '按钮文字',
                        page: '跳转页面',
                    },
                    {
                        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                        name: '按钮文字',
                        page: '跳转页面',
                    },
                ],
            },
            banner: [
                {
                    imgURL: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png',
                    toPage: '跳转页面',
                },
            ],
            images2: [
                {
                    imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bigzheng.png',
                    toPage: '',
                },
                {
                    imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/community/index1%20(3).png',
                    toPage: '',
                },
            ],
            data: {
                title: '大小事都能修',
                artisan_num: '1800000',
                order_num: '70000000',
                is_true: '0',
                rate: '99',
            },
            hasdata: false,
            type: 1,
        };
    },
    created () {
        this.getInfo();
    },
    methods: {
        changeSet (str) {
            this.chooseset = str;
        },
        updateData (e, str) {
            this[str] = e;
        },
        changeisShow (val) {
            console.log(111, val);
            this.isShow = val;
        },
        getInfo () {
            this.$axios.post(this.$api.repair.shop.diyinfo).then(res => {
                if (res.code == 0) {
                    if (res.result.page_values) {
                        let info = res.result.page_values;
                        info.forEach(item => {
                            if (item.data) {
                                this[item.module_id] = item.data;
                            }
                        });
                        this.hasdata = true;
                        this.type = res.result.type;
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        submit () {
            let info = [
                {
                    module_id: 'banner',
                    data: this.banner,
                },
                {
                    module_id: 'buttons',
                    data: this.buttons,
                    isShow: this.isShow,
                },
                {
                    module_id: 'images',
                    data: this.images,
                },
                {
                    module_id: 'images2',
                    data: this.images2,
                },
                {
                    module_id: 'data',
                    data: this.data,
                },
            ];
            let data = {
                page_name: '都能修首页',
                show_type: '2,3,4,5,6,7',
                page_value: info,
                page_type: '2',
            };
            if (this.hasdata) {
                data.type = this.type;
            }
            this.$axios.post(this.$api.repair.shop.diyinfoSet, data).then(res => {
                if (res.code == 0) {
                    this.$message.success('保存成功');
                    this.getInfo();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>
 
<style lang="less" scoped>
.el-main {
    background-color: #fff;
    min-height: 100%;
    padding-top: 0;
    padding-right: 0;
    display: flex;
    align-items: flex-start;

    .box {
        width: 384px;
        height: 770px;
        background-size: 100% 100%;
        margin-right: 20px;
        margin-left: 20%;
        margin-top: 20px;
        background-image: url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/phonebg.png);
        position: relative;

        .imagebg {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 15;
        }

        .logo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            width: 100%;
            position: absolute;
            top: 20px;
            left: 0;
            z-index: 15;
            padding: 0 12px;

            .image_logo {
                height: 24px;
                width: auto;
            }

            .image_menu {
                height: 28px;
                width: auto;
            }
        }

        .contain {
            width: 350px;
            height: 100%;
            overflow: auto;
            position: relative;
            background-color: #f8f8f8;
            padding-bottom: 53px;
            background-image: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/community/index/headerbg.png');
            background-size: contain;
            background-repeat: no-repeat;

            &::-webkit-scrollbar {
                width: 0px;
            }

            .location {
                padding: 33px 13px 11px;
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
                position: sticky;
                top: 0;
            }

            .search {
                width: 324px;
                height: 37px;
                background: rgba(255, 255, 255, 0.2);
                border-radius: 6px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                padding: 0 13px;
                box-sizing: border-box;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.7);
                line-height: 23px;

                img {
                    width: 19px;
                    height: 19px;
                    margin-right: 7px;
                }
            }

            .part1 {
                padding: 13px;
                background-color: #fff;
                margin: 7px 13px;
                border-radius: 6px;

                .slogan {
                    width: auto;
                    height: 20px;
                    margin: 0 13px;
                }

                .images {
                    margin-top: 13px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 2px;

                    img {
                        width: 142px;
                        height: 70px;
                        border-radius: 4px;
                        margin-bottom: 9px;
                    }
                }

                .buttons {
                    margin-top: 20px;

                    .button {
                        display: flex;
                        justify-content: space-between;

                        .button_item {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            color: #333333;
                            font-size: 12px;

                            img {
                                width: 32px;
                                height: 32px;
                                margin-bottom: 6px;
                            }
                        }
                    }
                }
            }

            .carousel {
                padding: 2px 12px;

                img {
                    width: 100%;
                    height: 100%;
                }

                .carousel_box {
                    border-radius: 6px;
                    position: relative;
                    z-index: 10;
                }
            }

            .part2 {
                padding: 18px 13px 21px;
                margin: 7px 13px;
                background-color: #fff;

                .title {
                    font-size: 15px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #1c1e1d;
                }

                .images2 {
                    margin-top: 14px;
                    display: flex;
                    justify-content: space-between;

                    .img1 {
                        width: 149px;
                        height: 135px;
                        margin-right: 6px;
                    }

                    .right {
                        flex: 1;

                        img {
                            width: 143px;
                            height: 65px;
                        }
                    }
                }
            }

            .data {
                margin: 28px 0;

                .row1 {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .line1 {
                        width: 20px;
                        height: 2px;
                        background: linear-gradient(-90deg, #999999 0%, rgba(237, 241, 242, 0) 100%);
                    }

                    .row1_title {
                        font-size: 15px;
                        font-family: FZZhengHeiS-B-GB;
                        font-weight: 400;
                        color: #999999;
                        margin: 0 6px;
                    }

                    .line2 {
                        width: 20px;
                        height: 2px;
                        background: linear-gradient(-270deg, #999999 0%, rgba(237, 241, 242, 0) 100%);
                    }
                }

                .row2 {
                    margin-top: 17px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .row2_item {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .row2_item_top {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .row2_item_num {
                                font-size: 19px;
                                font-family: DIN;
                                font-weight: bold;
                                font-style: italic;
                                color: #000000;
                                margin-right: 3px;
                            }

                            .row2_item_unit {
                                font-size: 10px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #000000;
                            }
                        }

                        .row2_item_text {
                            font-size: 9px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                            margin-top: 7px;
                        }
                    }

                    .line3 {
                        width: 1px;
                        height: 19px;
                        background: #cfd5d4;
                        margin: 0 24px;
                    }
                }
            }
        }

        .imagetabbar {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 53px;
            z-index: 100;
        }
    }

    .setting {
        flex-shrink: 0;
        width: 596px;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        padding-left: 20px;

        .setting_header {
            border-bottom: 1px solid #eee;
            height: 70px;
            display: flex;
            align-items: center;
            padding: 0 30px;
        }

        .setting_part {
            font-size: 14px;
        }
    }
}

/deep/.el-carousel__indicators {
    .el-carousel__indicator--horizontal {
        padding: 8px 2px;
    }

    .el-carousel__button {
        width: 4px;
        height: 4px;
        border-radius: 2px;
    }

    .is-active button {
        width: 18px;
    }
}</style>
 