<template>
    <div>
        <!-- <ToPage ref="toPage" @getPage="getPageRes"></ToPage> -->
        <ul class="pageList">
            <li @click="chooseIndex('index')" :class="activeSign == 'index' ? 'activeSign' : ''">商城</li>
            <li v-for="(item, index) in listPage" :key="index" @click="chooseIndex(item.sign)"
                :class="activeSign == item.sign ? 'activeSign' : ''">
                {{ item.name }}
            </li>
        </ul>
        <div class="contantBox">
            <!-- 商城选项 -->
            <div class="boxItem" v-if="activeSign == 'index'">
                <No v-if="shopPage.length == 0"></No>
                <ul class="shoplist" v-else>
                    <li :class="[, 'mouse']" v-for="(item, index) in shopPage" :key="index">
                        <span>{{ item.name }}</span>
                        <span class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</span>
                    </li>
                </ul>
            </div>
            <!-- 商品分类 -->
            <div class="boxItem" v-if="activeSign == 'goodsCategory'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入商品分类名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else>
                            <div :class="[, 'mouse']" v-for="(item, index) in selectList" :key="index">
                                <div class="first" @click="item.flag = !item.flag">
                                    <div :style="{ paddingLeft: item._child ? '' : '34px' }">
                                        <i v-if="item._child"
                                            :class="item.flag ? 'el-icon-remove-outline' : 'el-icon-circle-plus-outline'"></i>
                                        {{ item.name }}
                                    </div>
                                    <div>一级</div>
                                    <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                                </div>
                                <div v-show="item.flag">
                                    <div v-for="(item1, index1) in item._child" :key="index1" class="first second">
                                        <div>{{ item1.name }}</div>
                                        <div>二级</div>
                                        <div class="chooseShopItem" @click="e => chooseShopItem(e, item1)">选择</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 商品详情 -->
            <div class="boxItem" v-if="activeSign == 'goodsDetail'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入商品名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="goodsDetail" @scroll="scrollEvent">
                            <div :class="['txtItem', 'mouse']" v-for="(item, index) in selectList" :key="index"
                                class="goodsList">
                                <div class="leftGoods">
                                    <div class="goodsImg">
                                        <img :src="item.goods_cover_picture" alt="" />
                                    </div>
                                    <div class="goodsName">{{ item.goods_name }}</div>
                                </div>
                                <div>¥{{ item.goods_price }}</div>
                                <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 文章 -->
            <div class="boxItem" v-if="activeSign == 'articleDetails'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入文章名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="txtListBox" @scroll="scrollEvent">
                            <div :class="['txtItem', 'mouse']" class="txtItem" v-for="(item, index) in selectList"
                                :key="index">
                                <div class="txtItemLeft">{{ item.title }}</div>
                                <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 自定义页面 -->
            <div class="boxItem" v-if="activeSign == 'customPage'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入自定义页面名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="txtListBox" @scroll="scrollEvent">
                            <div :class="['txtItem', 'mouse']" v-for="(item, index) in selectList" :key="index">
                                <div class="txtItemLeft">{{ item.name }}</div>
                                <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 门店详情 -->
            <div class="boxItem" v-if="activeSign == 'storeList'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入门店名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="txtListBox" @scroll="scrollEvent">
                            <div :class="['txtItem', 'mouse']" v-for="(item, index) in selectList" :key="index">
                                <div class="txtItemLeft">{{ item.store_name }}</div>
                                <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 优惠券列表 -->
            <div class="boxItem" v-if="activeSign == 'myCouponList'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入优惠券名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="txtListBox" @scroll="scrollEvent">
                            <div :class="['txtItem', 'mouse']" v-for="(item, index) in selectList" :key="index">
                                <div>
                                    {{ item.name }}
                                </div>
                                <div :style="{
                                    color: item.activity_status === 0 || item.activity_status === -1 ? 'red' : 'green',
                                    cursor: item.activity_status === 0 || item.activity_status === -1 ? 'default' : 'pointer',
                                }" @click="e => chooseShopItem(e, item)">
                                    {{ item.activity_status === 0 ? '未开始' : item.activity_status === -1 ? '已结束' : '可使用' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--拼团商品详情 -->
            <div class="boxItem" v-if="activeSign == 'groupGoodsDetail'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入拼团商品名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="goodsDetail" @scroll="scrollEvent">
                            <div :class="['txtItem', 'mouse']" v-for="(item, index) in selectList" :key="index"
                                class="goodsList">
                                <div class="leftGoods">
                                    <div class="goodsImg">
                                        <img :src="item.picture" alt="" />
                                    </div>
                                    <div class="goodsName">{{ item.goods_name }}</div>
                                </div>
                                <div>¥{{ item.group_price }}</div>
                                <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--服务分类 -->
            <div class="boxItem" v-if="activeSign == 'serviceList'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入服务分类名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="goodsDetail" @scroll="scrollEvent">
                            <div :class="['txtItem', 'goodsList', 'noFlex']" v-for="(item, index) in selectList"
                                :key="index">
                                <div class="parentDiv" @click="item.showSon = !item.showSon">
                                    <div class="leftGoods">
                                        <div class="iconBox">
                                            <i :class="['el-icon-caret-right', item.showSon ? 'ro90' : '']"
                                                v-if="item._child && item._child.length > 0"></i>
                                        </div>
                                        <div class="goodsName">{{ item.classify_name }}</div>
                                    </div>
                                    <div class="chooseShopItem" @click.stop="e => chooseShopItem(e, item)">选择</div>
                                </div>
                                <div class="sonDiv"
                                    :style="{ height: item.showSon ? 60 * item._child.length + 'px' : '0px' }"
                                    v-if="item._child && item._child.length > 0">
                                    <div v-for="(item2, index2) in item._child" :key="index2">
                                        <div class="leftGoods">
                                            <div class="goodsImg">
                                                <img :src="item2.picture" alt="" />
                                            </div>
                                            <div class="goodsName">{{ item2.name }}</div>
                                        </div>
                                        <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--回收分类 -->
            <div class="boxItem" v-if="activeSign == 'Recycle'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入回收分类名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="goodsDetail" @scroll="scrollEvent">
                            <div :class="['txtItem', 'goodsList', 'noFlex']" v-for="(item, index) in selectList"
                                :key="index">
                                <div class="parentDiv" @click="item.showSon = !item.showSon">
                                    <div class="leftGoods">
                                        <div class="iconBox">
                                            <i :class="['el-icon-caret-right', item.showSon ? 'ro90' : '']"
                                                v-if="item._child && item._child.length > 0"></i>
                                        </div>
                                        <div class="goodsImg">
                                            <img :src="item.picture" alt="" />
                                        </div>
                                        <div class="goodsName">{{ item.name }}</div>
                                    </div>
                                    <div class="chooseShopItem" @click.stop="e => chooseShopItem(e, item)">选择</div>
                                </div>
                                <div class="sonDiv"
                                    :style="{ height: item.showSon ? 60 * item._child.length + 'px' : '0px' }"
                                    v-if="item._child && item._child.length > 0">
                                    <div v-for="(item2, index2) in item._child" :key="index2">
                                        <div class="leftGoods">
                                            <div class="goodsImg">
                                                <img :src="item2.picture" alt="" />
                                            </div>
                                            <div class="goodsName">{{ item2.name }}</div>
                                        </div>
                                        <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 自定义表单 -->
            <div class="boxItem" v-if="activeSign == 'Diyform'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入表单名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="txtListBox" @scroll="scrollEvent">
                            <div :class="['txtItem', 'mouse']" class="txtItem" v-for="(item, index) in selectList"
                                :key="index">
                                <div class="txtItemLeft">{{ item.name }}</div>
                                <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- vr详情 -->
            <div class="boxItem" v-if="activeSign == 'vrIndex'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入vr名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="goodsDetail" @scroll="scrollEvent">
                            <div :class="['txtItem', 'mouse']" v-for="(item, index) in selectList" :key="index"
                                class="goodsList">
                                <div class="leftGoods">
                                    <div class="goodsImg">
                                        <img :src="item.picture" alt="" />
                                    </div>
                                    <div class="goodsName">{{ item.title }}</div>
                                </div>
                                <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 套餐列表 -->
            <div class="boxItem" v-if="activeSign == 'package'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入套餐名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="goodsDetail" @scroll="scrollEvent">
                            <div :class="['txtItem', 'mouse']" v-for="(item, index) in selectList" :key="index"
                                class="goodsList">
                                <div class="leftGoods">
                                    <div class="goodsImg">
                                        <img :src="item.cover_picture" alt="" />
                                    </div>
                                    <div class="goodsName">{{ item.package_name }}</div>
                                </div>
                                <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 卡项列表 -->
            <div class="boxItem" v-if="activeSign == 'cardList'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入卡项名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="goodsDetail" @scroll="scrollEvent">
                            <div :class="['txtItem', 'mouse']" v-for="(item, index) in selectList" :key="index"
                                class="goodsList">
                                <div class="leftGoods">
                                    <div class="goodsImg">
                                        <img :src="item.card_item_picture" alt="" />
                                    </div>
                                    <div class="goodsName">{{ item.card_item_name }}</div>
                                </div>
                                <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 服务列表 -->
            <div class="boxItem" v-if="activeSign == 'serviceClassify'">
                <div class="goodsBox">
                    <div class="goodsBoxtop">
                        <el-input v-model="goodsSearch" placeholder="请输入卡项名称"></el-input>
                        <el-button type="primary" @click="searchOne">搜索</el-button>
                    </div>
                    <div class="goodsBoxbot">
                        <No v-if="selectList.length == 0"></No>
                        <div v-else class="goodsDetail" @scroll="scrollEvent">
                            <div :class="['txtItem', 'mouse']" v-for="(item, index) in selectList" :key="index"
                                class="goodsList">
                                <div class="leftGoods">
                                    <div class="goodsImg">
                                        <img :src="item.service_cover_picture" alt="" />
                                    </div>
                                    <div class="goodsName">{{ item.service_name }}</div>
                                </div>
                                <div class="chooseShopItem" @click="e => chooseShopItem(e, item)">选择</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
const industryName = '/Community';//行业版本接口名称（用于更换重复接口）
export default {
    props: {
        type: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            // 所有路由
            pageArr: [],
            goodsSearch: '',
            activeSign: 'index',
            selectList: [],
            page: 1,
            rows: 10,
            maxPage: 0,
            // 接口哈希树
            InterfaceHash: {
                goodsCategory: { api: industryName + this.$api.goods.goodsClass },
                goodsDetail: { api: industryName + this.$api.goods.list },
                articleDetails: { api: this.$api.set.listArticle },
                customPage: { api: this.$api.shopDiy.getPageList },
                merchantDetails: { api: this.$api.store.storeList },
                myCouponList: { api: this.$api.push.couponList },
                groupGoodsDetail: { api: this.$api.goods.groupGoodsList },
                findInstallation: { api: this.$api.samecity.service.classifyList }, // 回收的服务分类
                Recycle: { api: this.$api.O2O.recycleList },
                Diyform: { api: this.$api.customform.list },
                vrIndex: { api: this.$api.VR.list },
                package: { api: this.$api.beauty.setMeal.setMealList },
                cardList: { api: this.$api.beauty.service.cardList },
                serviceClassify: { api: this.$api.repair.service.list },
                serviceList: { api: this.$api.repair.service.ClassifyList },
                storeList: { api: this.$api.repair.store.list }
            }
        };
    },
    methods: {
        getPageArr () {
            let that = this;
            this.$axios.post(this.$api.repair.shop.ShopPlugin, {}).then(res => {
                if (res.code == 0) {
                    that.pageArr = res.result.list;
                }
            });
        },
        // 滚定监听
        scrollEvent (e) {
            if (this.activeSign == 'goodsCategory' || this.activeSign == 'findInstallation') return;
            if (e.currentTarget.scrollTop + e.currentTarget.clientHeight >= e.currentTarget.scrollHeight) {
                // 业务逻辑...
                if (this.page < this.maxPage) {
                    this.page++;
                    this.getSelect();
                }
            }
        },
        // 获取信息
        getSelect () {
            if (this.activeSign == 'index') {
                return
            }
            let that = this
            let url = this.InterfaceHash[this.activeSign].api
            let obj = {
                page: this.page,
                rows: this.rows,
            };
            if (this.activeSign === 'goodsDetail') {
                obj.status = 1;
            } else if (this.activeSign === 'articleDetails' || this.activeSign === 'vrIndex') {
                obj.is_show = 2;
            } else if (this.activeSign === 'myCouponList') {
                obj.status = 2;
                obj.activity_status = 1;
            } else if (this.activeSign === 'cardList' || this.activeSign === 'serviceClassify') {
                obj.status = 2;
            }
            if (this.goodsSearch) {
                if (this.activeSign === 'goodsCategory' || this.activeSign === 'myCouponList' || this.activeSign == 'findInstallation' || this.activeSign == 'Diyform') {
                    obj.name = this.goodsSearch;
                } else if (this.activeSign === 'goodsDetail') {
                    obj.goods_name = this.goodsSearch;
                } else if (this.activeSign === 'articleDetails' || this.activeSign === 'vrIndex') {
                    obj.title = this.goodsSearch;
                } else if (this.activeSign === 'customPage' || this.activeSign === 'groupGoodsDetail') {
                    obj.keyword = this.goodsSearch;
                } else if (this.activeSign === 'merchantDetails' || this.activeSign === 'storeList') {
                    obj.store_name = this.goodsSearch;
                } else if (this.activeSign === 'package') {
                    obj.package_name = this.goodsSearch;
                } else if (this.activeSign === 'cardList') {
                    obj.card_item_name = this.goodsSearch;
                } else if (this.activeSign === 'serviceClassify') {
                    obj.service_name = this.goodsSearch;
                } else if (this.activeSign === 'serviceList') {
                    obj.classify_name = this.goodsSearch;
                }
            }
            this.$axios.post(url, obj).then(res => {
                if (res.code == 0) {
                    if (that.activeSign === 'goodsCategory') {
                        that.selectList = res.result;
                        that.selectList.map(item => {
                            that.$set(item, 'flag', false);
                        });
                    } else if (this.activeSign == 'findInstallation') {
                        res.result.map(item => {
                            that.$set(item, 'showSon', true);
                        });
                        that.selectList = that.selectList.concat(res.result);
                    } else {
                        that.selectList = that.selectList.concat(res.result.list);
                        that.maxPage = Math.ceil(res.result.total_number / that.rows);
                    }
                }
            });
        },
        // 选择
        chooseShopItem (e, item) {
            e.stopPropagation();
            if (this.activeSign == 'index') {
                this.$emit('getPage', item);
                this.activeSign = 'index';
            } else if (this.activeSign == 'myCouponList') {
                if (item.activity_status === 0 || item.activity_status === -1) {
                    this.$message.warning('不可选择暂未开始或者已结束的优惠券！！！');
                } else {
                    this.$emit('getPage', item);
                    this.activeSign = 'index';
                }
            } else if (this.activeSign == 'groupGoodsDetail') {
                // 拼团商品
                let nowRoute = this.listPage.find(data => data.sign === this.activeSign).wxroute;
                let lastIndex = nowRoute.indexOf('&');
                let first = nowRoute.substring(0, lastIndex);
                let second = nowRoute.substring(lastIndex);
                item.wxroute = `${first}${item.goods_id}${second}${item.id}`;
                this.$emit('getPage', item);
                this.activeSign = 'index';
            } else if (this.activeSign == 'articleDetails') {
                // 选择文章 需要带文章id和分类id
                let dataItem = this.listPage.find(data => data.sign === this.activeSign)
                dataItem.wxroute = `${dataItem.wxroute}${item.id}&tabId=${item.article_classify_id}`;
                this.$emit('getPage', dataItem);
                this.activeSign = 'index';
            } else if (this.activeSign == 'package' || this.activeSign == 'cardList') {
                let dataItem = this.listPage.find(data => data.sign === this.activeSign)
                this.$set(item, 'wxroute', `${dataItem.wxroute}${item.id}`)
                this.$emit('getPage', item);
                this.activeSign = 'index';
            } else {
                let dataItem = this.listPage.find(data => data.sign === this.activeSign)
                dataItem.wxroute = `${dataItem.wxroute}${item.id}`;
                this.$emit('getPage', dataItem);
                this.activeSign = 'index';
            }
        },
        chooseIndex (sign) {
            if (this.type === 'coupon' && sign != 'myCouponList') {
                this.$message({
                    message: '优惠券组件只可以选择优惠券',
                    type: 'warning',
                    customClass: 'mzindex',
                });
                return;
            } else if (this.type === 'collage' && sign != 'groupGoodsDetail') {
                this.$message({
                    message: '拼团组件只可以选择拼团商品',
                    type: 'warning',
                    customClass: 'mzindex',
                });
                return;
            } else if (this.type === 'setMeal' && sign != 'package') {
                this.$message({
                    message: '套餐组件只可以选择套餐',
                    type: 'warning',
                    customClass: 'mzindex',
                });
                return;
            } else if (this.type === 'cardItem' && sign != 'cardList') {
                this.$message({
                    message: '卡项组件只可以选择卡项',
                    type: 'warning',
                    customClass: 'mzindex',
                });
                return;
            }
            this.selectList = [];
            this.goodsSearch = '';
            this.page = 1;
            this.activeSign = sign;
            if (sign === 'index') return;
            this.getSelect();
        },
        searchOne () {
            this.page = 1;
            this.selectList = [];
            this.getSelect();
        },
    },
    computed: {
        shopPage () {
            if (this.pageArr == []) return [];
            return this.pageArr.filter(item => item.type == 1);
        },
        listPage () {
            if (this.pageArr == []) return [];
            return this.pageArr.filter(item => item.type == 2);
        },
    },
};
</script>
  
<style lang="less" scoped>
.pageList {
    display: flex;
    flex-wrap: wrap;

    &>li {
        padding: 0 20px;
        text-align: center;
        font-size: 16px;
        height: 30px;
        margin-top: 5px;
        line-height: 32px;
        border-radius: 5px;
        border: 1px solid #fff;
        cursor: pointer;
    }

    .activeSign {
        border: 1px solid #4c82ff !important;
        color: #4c82ff;
        background-color: #eef3ff;
    }
}

.contantBox {
    margin-top: 10px;

    .boxItem {
        .shoplist {
            margin-top: 20px;

            // border: 1px solid #e4e4e4;
            li {
                padding: 15px;
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                // &:not(:last-child) {
                //   border-bottom: 1px solid #e4e4e4;
                // }
            }

            .chooseShopItem {
                color: #6b97ff;
                cursor: pointer;
            }
        }

        .goodsBox {
            width: 100%;
            height: 500px;

            // border: 1px solid #ccc;
            .goodsBoxtop {
                display: flex;
                justify-content: space-between;
            }

            .goodsBoxbot {
                height: 460px;
                overflow: hidden;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                    /**/
                }

                &::-webkit-scrollbar-track {
                    background: #fff;
                    border-radius: 2px;
                }

                &::-webkit-scrollbar-thumb {
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: #f9f9f9;
                }

                &::-webkit-scrollbar-corner {
                    background: #204754;
                }

                .first {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 40px;
                    padding: 0 40px 0 60px;

                    // border-bottom: 1px solid #ccc;
                    &>div:first-child {
                        width: 200px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;

                        i {
                            margin-right: 20px;
                        }
                    }
                }

                .second {
                    &>div:first-child {
                        width: 200px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        padding-left: 60px;
                    }
                }

                .noFlex {
                    display: block !important;
                }

                .goodsDetail {
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 1px;
                    }

                    .goodsList {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 5px 30px 5px 15px;
                        position: relative;

                        // border-bottom: 1px solid #ccc;
                        .parentDiv {
                            width: 100%;
                            justify-content: space-between;
                            align-items: center;
                            display: flex;
                            margin-bottom: 10px;
                            cursor: pointer;

                            .iconBox {
                                display: flex;
                                align-items: center;
                            }

                            i {
                                font-size: 24px;
                                transition: all 0.3s;
                            }

                            .ro90 {
                                transform: rotate(90deg);
                            }
                        }

                        .sonDiv {
                            width: 100%;
                            overflow: hidden;
                            transition: all 0.3s;

                            &>div {
                                justify-content: space-between;
                                align-items: center;
                                display: flex;
                                padding-left: 80px;
                                margin-bottom: 10px;
                            }
                        }

                        .leftGoods {
                            display: flex;
                        }

                        .goodsImg {
                            width: 50px;
                            height: 50px;
                            margin: 0 30px 0 10px;

                            img {
                                width: 100% !important;
                                height: 100% !important;
                            }
                        }

                        .goodsName {
                            width: 300px;
                            text-align: left;
                            line-height: 50px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                }

                .txtListBox {
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;

                    .txtItem {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 50px;
                        height: 50px;

                        // border-bottom: 1px solid #ccc;
                        .txtItemLeft {
                            width: 500px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}

.chooseShopItem {
    color: #6b97ff;
    cursor: pointer;
}

.mouse:hover {
    background-color: #f5f7fa;
}
</style>
  